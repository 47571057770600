@import url(https://fonts.googleapis.com/css?family=Crete+Round:400,400i&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Header {
  text-align: center;
}

.imageBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

div {
  font-family: "Crete Round", serif;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.display-none {
  display: none;
}

.newentry {
  position: static;
  left: 10px;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.no-column {
  width: 10px;
}

.bg-options {
  z-index: 2;
  position: fixed;
  top: 0%;
  left: 0;
  right: 10%;
  bottom: 10%;
  opacity: 0.9;
  height: 100%;
}

.working-options {
  z-index: 2;
  position: fixed;
  top: 0%;
  left: 0;
  right: 10%;
  bottom: 0%;
  opacity: 0.9;
  height: 100%;
}

.statusStyle {
  z-index: 0;
  color: rgb(187, 0, 0);
  position: absolute;
  top: 40%;
  left: 16px;
  font-size: 70%;
}

.delete-options {
  z-index: 2;
  position: absolute;
  top: 0%;
  left: 0;
  right: 10%;
  bottom: 0%;
  opacity: 0.9;
  height: 100%;
}

.deleteAccount {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(0deg);
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(360deg);
            transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(0deg);
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(360deg);
            transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  -webkit-animation: 1.5s linear infinite spinner;
          animation: 1.5s linear infinite spinner;
  -webkit-animation-play-state: inherit;
          animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 200px;
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.details {
  max-width: 100%;
  position: relative;
  z-index: 3;
  max-height: 100%;
  word-break: break-word;
}

.word-break {
  word-break: break-all;
}

.prompt {
  z-index: 4;
  position: relative;

  height: 100%;
}

@media screen and (max-width: 992px) {
  .location-display-none {
    display: none;
  }
}

@media screen and (max-width: 660px) {
}

.money th {
  color: white;
  background-color: #333333;
}

.money tr {
  color: #3288d8;
}

.eventsTable {
  color: #3288d8;
}

.eventsTableRed {
  color: red;
}

.welcomeMessage {
  color: #fd774e;
  position: absolute;
  right: 0;
  font-size: 12px;
  top: 4px;
  z-index: 1;
  margin-right: 10px;
}

.headerMenu {
  font-size: 28px;
  color: #96ccff;
  z-index: 1;
  top: 15px;
  margin-right: 10px;
  padding: 10px;
  right: 0;
  position: absolute;
}

.headerStyle {
  height: 80%;
}

.headerStyle2 {
  height: 70px;
}

.teamBackground {
  background: rgb(103, 171, 202);
  color: rgb(56, 56, 56);
}

